
@font-face {
  font-family: 'AGFont';
  src: url('./assets/fonts/font3.woff2') format('woff2'),
    url('./assets/fonts/font3.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
    font-family: 'AGFont2';
    src: url('./assets/fonts/font4.woff2') format('woff2'),
      url('./assets/fonts/font4.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

// @font-face {
//   font-family: 'AGFont';
//   src: url('./assets/fonts/font2.woff2') format('woff2'), url('./assets/fonts/font2.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: auto;
// }

// // @font-face {
// //   font-family: 'AGFont';
// //   src: url('./assets/fonts/font.woff2') format('woff2'),
// //     url('./assets/fonts/font.woff') format('woff');
// //   font-weight: normal;
// //   font-style: normal;
// //   font-display: auto;
// // }

// /* latin-ext */
// @font-face {
//   font-family: 'Fraunces';
//   font-style: normal;
//   font-weight: 400;
//   src: url(https://fonts.gstatic.com/s/fraunces/v7/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxuTB_7Vp05GNyXkb24.woff)
//     format('woff');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }

// /* latin */
// @font-face {
//   font-family: 'Fraunces';
//   font-style: normal;
//   font-weight: 400;
//   src: url(https://fonts.gstatic.com/s/fraunces/v7/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxuTCf7Vp05GNyXk.woff)
//     format('woff');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
//     U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Cervo-Regular';
//   src: url('./assets/fonts/font.woff2') format('woff2'),
//     url('./assets/fonts/font.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: auto;
// }

// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-DemiIt.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-DemiIt.woff') format('woff');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-UltLtIt.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-UltLtIt.woff') format('woff');
//   font-weight: 200;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-Medium.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-It.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-It.woff') format('woff');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-HeavyIt.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-HeavyIt.woff') format('woff');
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-MediumIt.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-MediumIt.woff') format('woff');
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-BoldIt.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-BoldIt.woff') format('woff');
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-UltLt.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-UltLt.woff') format('woff');
//   font-weight: 200;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-Demi.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-Heavy.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-Heavy.woff') format('woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Avenir Next LT Pro';
//   src: url('./assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'AvenirNextW10';
//   src: url('./assets/fonts/AvenirNextW10-Heavy.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextW10-Heavy.woff') format('woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'AvenirNextW10';
//   src: url('./assets/fonts/AvenirNextW10-Medium.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextW10-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'AvenirNextLTW01';
//   src: url('./assets/fonts/AvenirNextLTW01-Regular.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextLTW01-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'AvenirNextW04';
//   src: url('./assets/fonts/AvenirNextW04-Bold.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextW04-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'AvenirNextW10-Demi';
//   src: url('./assets/fonts/AvenirNextW10-Demi.woff2') format('woff2'),
//     url('./assets/fonts/AvenirNextW10-Demi.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }
